export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <a className='logo' href='#top'>
            <img 
              src={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAyxJREFUWEfF12nIZ2MYBvDf2BprDLJkHWsJSZGdQcaUkS0ljaJQljQhIlu2SNniA0nKh5nQfBiSZfhkLbJmTww+oLFkz9I1PaeO03POf85rxtz11tv/3q7nee77uu8zzWqWaas5vz4Aa+NEnIL9sG0B+gPexJN4CJ//1wPUAMzFndh+QvA/cDeuwC9TBdIGkP9vwcUjg72NY7F0pN9y8zaAW6eQvMn5MfbHt2NBNACOx6Kxzh37xThubIwAWAcfYrsB56/xI3bAGgN2eYr3sBP+LEX6Cf7u8wmA0/Bwj8GrOB+vFP2muASXdp6vcV+Cx3FbK943eLQU9rvdPAEQZVquK0l+OH6u6M4rHdBV5aQH4KWKz1+4A5fjt0YfAOnlbSoOB+LFnpuJ31vYo6KfjcewXo/vC5iD75suqL3Pd9hkQkH1dc1ZuBI7Dvg/h6NTJzlJDcBX2HoCgOsLCXXNzsVlpWCHQoTAbgyAZdi4Yhn6HSKXJwoBdV1PwqHYCrOwWQ+KnwIyAJ7HYRWj+3B2j3PmQwqtRuW74KPity5yU/N74sxPgLRVKLgmN+A6/N5SHoRHsGXF4QPsVvn9QZxR+X1JAGyOzzC9B8QXeKq04944eOBhLyqt1jXJYPu04resucJrcPWEopukzrXviV97DL8sdfEvdQMg8z+tkeudimQch7QaxqzFyKCa0VW0iyjKXPW+IxEk+bxSF32u++C1inJpt4qPxDMjALxT5sLNuB0PVHzXKjFrnbaoDSDM9yyCdkhCXC+XZOGPq7BBmX6powBJj0d2xz04oifgvADYqBRgpmKttZLwHGQk5y9dEaY7GZmOXUkRvo/1sfPASVKUM5t9IBXcLJ5dn1xvJlhbnsZRI56qZnoqFjZPsCvuxyEty1D0tT19vUWp+KElZgjfXbgwBt0izBOEybIDvNFhwG7AmWU9D/WOkXtxQamZ3u+CFQ24Ydl+zsSaK+h0ensDW1lfRim27AHHYK8JYEJIWV6XLzsrC0D38GnPzJiQT1q0K+ms1wN4VQFoEp6ABQjVdyXUP2tVA0jS7A4ZdiGjTNxwxELclE+6/wPAYG3+A2LllVmvLCczAAAAAElFTkSuQmCC`}
              alt="NatJustGPT"
            />
            NatJus GPT <span>Beta</span>
          </a>
        </div>

        <div className='collapse navbar-collapse' id='bs-example-navbar-collapse-1'>
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#' className='btn btn-custom btn-lg page-scroll'>
                Teste agora
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
