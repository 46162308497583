import { Navigation } from './components/navigation'
import { Header } from './components/header'
import { Footer } from './components/footer'
import SmoothScroll from 'smooth-scroll'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  offset: 84,
})

const App = () => {
  return (
    <div>
      <Navigation />
      <Header />
      <Footer />
    </div>
  )
}

export default App
